import { Capacitor } from '@capacitor/core'
import { useOneSignal } from '@onesignal/onesignal-vue3'
import { logEvent } from '@/firebase.js'
import OneSignal from 'onesignal-cordova-plugin'

let oneSignalForWeb
if (!Capacitor.isNativePlatform()) {
  oneSignalForWeb = useOneSignal()
}

const sendTags = (name, tags) => {
  if (Capacitor.isNativePlatform()) {
    window.plugins.OneSignal.User.addTag(name, tags)
  } else {
    oneSignalForWeb.User.addTag(name, tags)
  }
}

const setEmail = (email) => {
  if (Capacitor.isNativePlatform()) {
    if (window.plugins.OneSignal) {
      window.plugins.OneSignal.User.addEmail(email)
    }
  } else {
    oneSignalForWeb.User.addEmail(email)
  }
}

const setExternalId = (id) => {
  if (Capacitor.isNativePlatform()) {
    if (window.plugins.OneSignal) {
      window.plugins.OneSignal.login(id)
    }
  } else {
    oneSignalForWeb.login(id)
  }
}

const requestNotificaitionPermission = async () => {
  const permission = await oneSignalForWeb.Notifications.permission
  if (!permission) {
    logEvent('notification_permission_fire')
    await oneSignalForWeb.Notifications.requestPermission(true)
  }
}

const requestPermission = async () => {
  if (!Capacitor.isNativePlatform()) {
    await requestNotificaitionPermission()
  } else {
    await OneSignal.Notifications.requestPermission(true)
  }
}

export default {
  sendTags,
  setEmail,
  requestPermission,
  setExternalId
}
